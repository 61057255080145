import { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Typewriter from 'typewriter-effect';
import "./fadein.css"

function Motto() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onCreateTextNode = ch => {
    const span = document.createElement("SPAN")
    const text = document.createTextNode(ch)
    span.appendChild(text)
    span.classList.add("fade-in")
    return span
  }

  return (
    <Box display={!isSmallScreen ? "inline-block" : "block"}>
      {
        !isSmallScreen ?
          <>
            <Typography fontSize={{ sm: 20, md: 20, lg: 40 }} align="left">
              <Typewriter
                options={{
                  delay: 30,
                  cursor: '',
                  onCreateTextNode
                }}
                onInit={(typewriter) => {
                  typewriter.typeString('"Ovládni svou mysl, ')
                    .pauseFor(700)
                    .typeString('ovládneš sám sebe.')
                    .start();
                }}

              />
            </Typography>
            <Typography fontSize={{ sm: 20, md: 20, lg: 40 }} ml={{ xs: 10, md: "10%" }}>
              <Typewriter
                options={{
                  delay: 30,
                  cursor: '',
                  onCreateTextNode
                }}
                onInit={(typewriter) => {
                  typewriter.pauseFor(3000)
                    .typeString('Poznej lásku, ')
                    .pauseFor(700)
                    .typeString('poznáš sebe."')
                    .start();
                }}
              />
            </Typography>
          </>
          :
          <Typography variant="h5" align="left">
            <Typewriter
              options={{
                delay: 30,
                cursor: '',
                onCreateTextNode
              }}
              onInit={(typewriter) => {
                typewriter.typeString('"Ovládni svou mysl, <br />')
                  .pauseFor(700)
                  .typeString('ovládneš sám sebe.<br />')
                  .pauseFor(700)
                  .typeString('Poznej lásku, ')
                  .pauseFor(700)
                  .typeString('poznáš sebe."')
                  .start();
              }}
            />
          </Typography>
      }
      <Typography variant="body1" align="right" sx={{ fontStyle: "italic" }}>
        <Typewriter
          options={{
            delay: 60,
            cursor: '',
            onCreateTextNode
          }}
          onInit={(typewriter) => {
            typewriter
              .pauseFor(5200)
              .pasteString("Zdeněk Chmela")
              .start();
          }}
        />
      </Typography>
    </Box>
  );
}

export default Motto;