import { Typography } from "@mui/material";

function NameTitle({ children, ...props }) {
  return (
    <Typography
      variant="h3"
      sx={{
        fontWeight: 300,
      }}
      fontSize={{ xs: 30, md: 50 }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default NameTitle;