import { Divider } from '@mui/material';
import EyeIcon from '@mui/icons-material/VisibilityOutlined';
import { useMediaQuery } from '@mui/material';

function EyeIconDivider({ ...props }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Divider sx={{
      ".MuiDivider-wrapper": {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      },
      "&::before, &::after": {
        borderColor: "white",
        borderWidth: "2px",
      }
    }}
      orientation={isSmallScreen ? null : "vertical"}
      flexItem
      {...props}
    >
      <EyeIcon />
    </Divider>
  );
}

export default EyeIconDivider;