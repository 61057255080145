import { InlineWidget } from "react-calendly";
import { Box } from "@mui/material";
import ContentTitle from "./ContentTitle";

function Booking() {
  return (
    <Box
      id="booking"
      sx={{
        width: '100%',
        textAlign: 'center',
      }}
      mt={10}
    >
      <ContentTitle>
        Objednat se
      </ContentTitle>
      <InlineWidget
        url="https://calendly.com/chmelaondrej/za-zrak"
        styles={{
          minWidth: '320px',
          height: '1000px',
          marginTop: '2rem',
        }}
      />
    </Box>
  );
}

export default Booking;