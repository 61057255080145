import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function ContentTitle({ children, ...props }) {
  const theme = useTheme();

  return (
    <Typography
      variant="h2"
      sx={{
        textDecorationThickness: 1,
        // make the decoration further from the text
        textUnderlineOffset: "0.1em",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 300,
        fontSize: { xs: 50 },
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default ContentTitle;