import { Box, Typography, Unstable_Grid2 as Grid, useMediaQuery } from '@mui/material';
import ContentTitle from '../ContentTitle';
import EyeIconDivider from './EyeIconDivider';
import AboutPerson from './AboutPerson';
import { Parallax, Background } from 'react-parallax';
import treeImg from "../images/jeremy-bishop-EwKXn5CapA4-unsplash.jpg";

function About() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      id="about"
      sx={{
        width: '100%',
      }}
      mt={10}
    >
      <Parallax strength={200} disabled={isSmallScreen}>
        <Background>
          <img
            src={treeImg}
            alt="tree"
            style={{
              willChange: "transform",
              transform: `${!isSmallScreen ? "translate(0, -40%)" : "translate(0, 10%) scale(1.8)"}`,
              width: "100vw",
              height: "auto",
            }}
          />
        </Background>
        <Grid container
          sx={{
            color: 'white',
            paddingLeft: { xs: '10%' },
            paddingRight: { xs: '10%' },
            background: "rgba(0, 0, 0, 0.4)",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
          pt={{ xs: 10, lg: 20 }}
          pb={{ xs: 10, lg: 20 }}
          justifyContent={{ lg: "space-between" }}
        >
          <Grid container xs={12} lg={4} justifyContent={{ xs: "center", lg: "right" }} alignItems='center'>
            <ContentTitle>
              <b>O Za Zraku</b>
            </ContentTitle>
          </Grid>
          <Grid container xs={12} lg={1} mt={{ xs: 5, md: 0 }} justifyContent='center'>
            <EyeIconDivider />
          </Grid>
          <Grid xs={12} lg={4} mt={{ xs: 5, md: 0 }}>
            <Typography variant="body1" fontSize={{ xs: 17, md: 20 }} sx={{
              textAlign: "justify",
              fontWeight: "bold",
            }}>
              Tvoříme pro vás prostor k setkávání, hlubokému léčení a možnost jít za svoje smysly a ZA svůj ZRAK.
              V prostoru podvědomí nalézáme příčiny našich emočních traumat, setkáváme se s příčinami našich nemocí a bolestí.
              V dobách minulých se o nás mluvilo jako o léčitelích, v současné době se s námi můžete setkat jako s terapeuty,
              kouči nebo průvodci. Ale člověk se vždy musí chtít uzdravit sám. Jsme tu, abychom vám ukázali cestu k sebeléčení.
              Naučíme vás vcházet do prostoru za své smysly, tedy ZA ZRAK, do hlubin podvědomí, kde sídlí zdroje všech vašich nemocí a bolestí.
            </Typography>
          </Grid>
        </Grid>
      </Parallax>
      <Box
        mt={{ xs: 10, lg: 30 }}
        width='100%'
        display='flex'
        justifyContent='center'
      >
        <ContentTitle>
          O nás
        </ContentTitle>
      </Box>
      <Grid container
        mt={10}
        sx={{
          padding: '0 10%'
        }}
      >
        <Grid xs={12} lg={6}>
          <AboutPerson
            name="Ondřej Chmela"
            description={
              `Osud tomu chtěl, abych se narodil do rodiny mistra, tak jsem měl již od ranného mládí možnost nahlížet za zrak a oponu matrixu, různých dovedností a praktik.
              Úspěšně jsem prošel a dokončil cestu vědomí a pokorně studuji dál. Prováděním lidí jimi samými a jejich zatím skrytými kvalitami se zabývám 2 roky a je to nedílnou součástí mé duchovní cesty.`
            }
            imgSrc={process.env.PUBLIC_URL + '/1.jpeg'}
          />
        </Grid>
        <Grid xs={12} lg={6} mt={{ xs: 10, md: 5, lg: 0 }}>
          <AboutPerson
            name="Zdeněk Chmela"
            description={
              `Osud mě učil vše zvládnout za jakýchkoli podmínek. I když jsem byl postaven do beznadějných situací, našel jsem cestu. Na ní jsem sbíral mnoho cenných zkušeností s tíhou životních zkoušek, které na lidech leží.
              Cestoval jsem po celém světě abych poznával moudrost jiných kultur, pracoval s energetickými poli mnohých silových míst naší planety jako pyramidy egyptské, mexické, bosenské, Angkorwat, Amazonie apod.. Setkával a pracoval jsem tam se zajímavými otevřenými lidmi a s jejich pohledem na kosmologii, mystiku, šamanismus, náboženství a sociální struktury lidí. Až inspirace a práce s těmito lidmi, kteří došli ve svém poznání hluboko do svého nitra mě dala zúročit mé vlastní bádání a studium. Ač jsem prostudoval stohy moudrých knih, tak až živá zkušenost vlastních hlubokých prožitků či postrčení od mistrů jsou nejcennější pomocníci na cestě.
              Dnes to vše realizuji a pomáhám již více jak 10 let ostatním překonávat jejich úkoly a útrapy ať fyzické tak psychické.`
            }
            imgSrc={process.env.PUBLIC_URL + '/2.jpg'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default About;
