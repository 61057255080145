import { Box, useMediaQuery } from '@mui/material'
import NameTitle from './NameTitle'
import PersonDescription from './PersonDescription'

function AboutPerson({ name, description, imgSrc }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      paddingLeft: { xs: 0, md: 2, lg: 6 },
      paddingRight: { xs: 0, md: 2, lg: 6 },
    }}
    >
      <img src={imgSrc} alt={name} 
      width={isSmallScreen ? "280px" : "400px"} 
      height={isSmallScreen ? "308px" : "441"} 
      />
      <NameTitle>
        {name}
      </NameTitle>
      <PersonDescription>
        {description}
      </PersonDescription>
    </Box>
  )
}

export default AboutPerson