import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import Links from './Welcome/Links';


function Footer() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1rem',
        backgroundColor: 'background.secondary',
      }}
      mt={10}
    >
      <Links color="secondary" flexDirection={isSmallScreen ? "column" : "row"} />
    </Box>
  );
}

export default Footer;