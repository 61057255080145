import { Box } from "@mui/material";
import ScrollLink from "../ScrollLink";

function Links({ color, fontSize, flexDirection = "column", ...props }) {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: flexDirection,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2
      }}
      {...props}
    >
      <ScrollLink to="about" color={color} fontSize={fontSize}>O Za Zraku</ScrollLink>
      <ScrollLink to="contact" color={color} fontSize={fontSize}>Kontakt</ScrollLink>
      <ScrollLink to="booking" color={color} fontSize={fontSize}>Objednat se</ScrollLink>
    </Box>
  );
}

export default Links;