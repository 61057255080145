import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EyeIcon from '@mui/icons-material/VisibilityOutlined';

function Title() {
  const theme = useTheme();

  return (
    <Box sx={{ mt: { md: 20, xs: 10 } }}>
      <Typography
        variant="h1"
        sx={{
          textDecoration: "underline",
          textDecorationThickness: 1,
          // make the decoration further from the text
          textUnderlineOffset: "0.1em",
          fontWeight: 100,
          fontSize: { xs: 65, md: 100, lg: 150 },
        }}
      >
        ZA-ZRAK
      </Typography>
    </Box>
  );
}

export default Title;