import { Box, Typography, Link } from "@mui/material";
import ContentTitle from "./ContentTitle";

function Contacts() {
  return (
    <Box
      id="contact"
      sx={{
        width: '100%',
      }}
      mt={10}
    >
      <ContentTitle>
        Kontakt
      </ContentTitle>
      <Box textAlign="start" mt={4}>
        <Typography variant="body1">
          <b>Ondřej Chmela</b>
        </Typography>
        <Typography variant="body1">
          Telefon: +420 605 784 410
        </Typography>
        {"Email: "}
        <Link href="mailto:archetypyduse@gmail.com" underline="hover">
          archetypyduse@gmail.com
        </Link>
        <Typography variant="body1">
          Adresa: V Jámě 1, Praha 1
        </Typography>
      </Box>
    </Box>
  );
}

export default Contacts;