import { Typography } from "@mui/material";

function PersonDescription({ children, ...props }) {
  return (
    <Typography variant="body1" fontSize={{ xs: 17, md: 20 }} 
      sx={{
        justifyContent: "flex-start",
        textAlign: "justify",
      }}
    >
      {
        children.split('\n').map((line, index) => (<>{line} <br /><br /></>))
      }
    </Typography>
  );
}

export default PersonDescription;