import { Welcome, About, Booking, Contacts, Footer } from './components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery, Box } from '@mui/material';
import ScreenSizeContext from './context/ScreenSizeContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8a5428',
    },
    secondary: {
      main: '#fffffa',
    },
    background: {
      default: '#fffffa',
      secondary: '#bf7e2e',
    },
  },
});


function Page() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  // a page with welcome, contact and about sections and a link that redirects to calendly
  return (
    <ScreenSizeContext.Provider value={{ isSmallScreen }}>
      <Box
        sx={{
          height: '100vh', // Fill the entire viewport height
          padding: '0 10%', // Apply 10% padding to both left and right sides
        }}
      >
        <Welcome />
      </Box>
      <Box>
        <About />
      </Box>
      <Box
        sx={{
          padding: '0 10%', // Apply 10% padding to both left and right sides
        }}
      >
        <Booking />
        <Contacts />
      </Box>
      <Footer />
    </ScreenSizeContext.Provider >
  );
}

// export the page wrapped in a theme provider
export default function ThemeWrappedPage() {
  return (
    <ThemeProvider theme={theme}>
      <Page />
    </ThemeProvider>
  );
}
