import { Grid, Box, IconButton, useMediaQuery } from '@mui/material';
import { Link as ReactScrollLink } from 'react-scroll';
import Motto from "./Motto";
import Title from './Title';
import Links from './Links';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Welcome() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  // a welcome section with a title, motto and links that scroll to contact and about sections
  return (
    <Grid container
      id="welcome"
      height="100%"
    >
      <Grid container direction="column" justifyContent={ isSmallScreen ? "space-evenly" : "space-between"} xs={12} md={8} sx={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
        <Grid item md>
          <Title />
        </Grid>
        {
          isSmallScreen ?
            <Grid item md>
              <Links />
            </Grid>
            :
            <Grid item md={3}>
              <Motto />
            </Grid>
        }
      </Grid>
      <Grid item xs={12} md={4}>
        {
          isSmallScreen ?
            <Grid item height="200px">
              <Motto />
            </Grid>
            :
            <Grid item mt="40%">
              <Links fontSize={21} />
            </Grid>
        }
      </Grid>

      { /*
        !isSmallScreen &&

        <Box mt="auto" sx={{
          animation: 'bounce 2s infinite',

          "@keyframes bounce": {
            "0%": {
              transform: 'translateY(0)',
            },
            "50%": {
              transform: 'translateY(-10px)',
            },
            "100%": {
              transform: 'translateY(0)',
            },
          }
        }}>
          <ReactScrollLink
            to="about"
            smooth={true}
            duration={1000}
            offset={-70}
            spy={true}
            activeClass="active"
          >
            <IconButton size='large'>
              <KeyboardArrowDownIcon />
            </IconButton>
          </ReactScrollLink>
        </Box>
      */}
    </Grid>
  );
}

export default Welcome;
