import { Link as ReactScrollLink } from 'react-scroll';
import { Button } from '@mui/material';

function ScrollLink({ to, children, fontSize, ...props }) {
  return (
    <ReactScrollLink
      to={to}
      smooth={true}
      duration={1000}
      offset={-70}
      spy={true}
      activeClass="active"
    >
      <Button size='large' style={{ fontSize: fontSize }} {...props}>{children}</Button>
    </ReactScrollLink>
  );
}

export default ScrollLink;